export const colors = {
  brand: {
    red: "#e15973",
    blue: "#59abdf",
    yellow: "#dfde4b",
    lightRed: "#de4865",
    lightBlue: "#32a8e3",
    lightYellow: "#ffe500",
    black: "#342500",
    orange: "orange",
    lightOrange: "#ffe500",
    green: "#006400",
    lightGreen: "#228B22",
    gray: "#bfbfbf",
    lightGray: "#d9d9d9",
    purple: "#cc00cc",
    white: "#f2f2f2",
  },
  monster: {
    background: "#eaab25",
    primary: "#2635bd",
    primaryHover: "#2230aa",
    secondary: "#bd2667",
    secondaryHover: "#b72f6a",
  },
  npc: {
    background: "#eaab25",
    primary: "#2635bd",
    primaryHover: "#2230aa",
    secondary: "#bd2667",
    secondaryHover: "#b72f6a",
    // background: "#59abdf",
    // primary: "#6200b8",
    // primaryHover: "#8566b3",
    // secondary: "#a0408a",
    // secondaryHover: "#a04062",
  },
  wholesale: {
    background: "#eaab25",
    primary: "#2635bd",
    primaryHover: "#2230aa",
    secondary: "#bd2667",
    secondaryHover: "#b72f6a",
  },
};
