import axios from "axios";
import { API_KEY } from "./constants/General";

/**
 * Generates a sum equation with variables between 1 and 10
 * @return {[string, number]} equation as a string and the answer as a number
 */
export function randomEquationAndAnswer(): [string, number] {
  const randNum1 = Math.floor(Math.random() * 10);
  const randNum2 = Math.floor(Math.random() * 10);
  const answer = randNum1 + randNum2;
  const equation = `${randNum1.toString()} + ${randNum2.toString()} = `;
  return [equation, answer];
}

/**
 * Make a post request with our api key.
 * @param apiUrl The url the api goes to.
 */
export async function post(apiUrl: string, body?: any): Promise<void> {
  axios
    .post(apiUrl, body, {
      headers: { "x-api-key": API_KEY },
    })
    .catch((error) => alert(error));
}

export function parseUrlParams(params: string): Map<string, string> {
  const paramMap = new Map();
  const paramList = params.slice(1).split("&");
  paramList.forEach((paramValuePair: string) => {
    const [param, value] = paramValuePair.split("=");
    paramMap.set(param, value);
  });
  return paramMap;
}

export function generateS3Key(userId: string, name: string, directory: string, fileType = "pdf") {
  name = name.replaceAll(" ", "-");
  return `${userId}/${directory}/${name}.${fileType}`;
}

export function enumToString(enumClass: any): string[] {
  return Object.values(enumClass).map((e) => e.toString());
}
