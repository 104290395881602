import React from "react";
import { UserProvider } from "./src/context/AuthState";
import { ApiClientProvider } from "./src/context/ApiClient.tsx";
import { TrackingHistoryProvider } from "./src/context/TrackingHistory";
import { AlertProvider } from "./src/context/Alert";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./src/theme/Index";
import { Fonts } from "./src/Fonts";

// Wraps every page in a component
export function wrapPageElement({ element, props }) {
  return (
    <div>
      <ChakraProvider theme={theme}>
        <Fonts />
        {element}
      </ChakraProvider>
    </div>
  );
}

export function wrapRootElement({ element }) {
  return (
    <UserProvider>
      <ApiClientProvider>
        <AlertProvider>
          <TrackingHistoryProvider>{element}</TrackingHistoryProvider>
        </AlertProvider>
      </ApiClientProvider>
    </UserProvider>
  );
}
