export const Input = {
  // style object for base or default style
  // styles for different sizes ("sm", "md", "lg")
  baseStyle: {
    field: {
      bg: "white",
      borderStyle: "solid",
      _fouces: {},
    },
  },
  sizes: {
    sm: {
      field: {
        height: 8,
        borderWidth: "2px",
        borderRadius: "4px",
      },
    },

    md: {
      field: {
        height: 12,
        borderWidth: "2px",
        borderRadius: "4px",
      },
    },

    lg: {
      field: {
        height: 16,
        borderWidth: "2px",
        borderRadius: "4px",
        fontSize: "lg",
      },
    },

    xl: {
      field: {
        height: 16,
        borderWidth: "2px",
        borderRadius: "4px",
        fontSize: "lg",
      },
    },
  },
  variants: {
    yellow: {
      field: {
        borderColor: "brand.yellow",
        _focus: {
          borderColor: "brand.lightYellow",
        },
        _hover: {
          borderColor: "brand.lightYellow",
        },
      },
    },
    blue: {
      field: {
        color: "black",
        borderColor: "brand.blue",
        _focus: {
          borderColor: "brand.lightBlue",
        },
        _hover: {
          borderColor: "brand.lightBlue",
        },
      },
    },
    red: {
      field: {
        borderColor: "brand.red",
        _focus: {
          borderColor: "brand.lightRed",
        },
        _hover: {
          borderColor: "brand.lightRed",
        },
      },
    },
    green: {
      field: {
        borderColor: "brand.green",
        _focus: {
          borderColor: "brand.lightGreen",
        },
        _hover: {
          borderColor: "brand.lightGreen",
        },
      },
    },
    black: {
      field: {
        borderColor: "black",
        _focus: {
          borderColor: "brand.gray",
        },
        _hover: {
          borderColor: "brand.gray",
        },
      },
    },
  },
};
