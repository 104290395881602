/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode } from "react";
import { Amplify } from "@aws-amplify/core";
import { Storage } from "@aws-amplify/storage";
import { Auth } from "@aws-amplify/auth";
import awscontext from "../aws-exports";
import { navigate } from "gatsby";
import { generateS3Key } from "../Utils";

Amplify.configure(awscontext);

export interface AuthState {
  user: string | null;
  id: string | null;
  login: (email: string, password: string) => Promise<void>;
  amazonLogin: () => Promise<void>;
  googleLogin: () => Promise<void>;
  logout: () => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  confirmUser: (usernameOrEmail: string, code: string) => void;
  loggedIn: boolean;
  uploadFile: (name: string, data: any, callback?: () => void) => Promise<void>;
  getPresignedUrl: (name: string, directory: string) => Promise<string>;
  getPresignedUrlWithKey: (key: string) => Promise<string>;
  getKeys: () => Promise<any>;
}

const dummyAuthState: AuthState = {
  user: "",
  id: "",
  login: () => {
    throw Error("User Provider not set");
  },
  logout: () => {
    throw Error("User Provider not set");
  },
  signUp: () => {
    throw Error("User Provider not set");
  },
  confirmUser: () => {
    throw Error("User Provider not set");
  },
  loggedIn: false,
  uploadFile: () => {
    throw Error("User Provider not set");
  },
  getPresignedUrl: () => {
    throw Error("User Provider not set");
  },
  getKeys: () => {
    throw Error("User Provider not set");
  },
  amazonLogin: () => {
    throw Error("User Provider note set");
  },
  googleLogin: () => {
    throw Error("User Provider note set");
  },
  getPresignedUrlWithKey: () => {
    throw Error("User Provider not set");
  },
};

const UserContext = React.createContext(dummyAuthState);

interface ChildrenProps {
  children: ReactNode;
}

// Create a "controller" component that will calculate all the data that we need to give to our
// components below via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
export const UserProvider = ({ children }: ChildrenProps) => {
  const [user, setUser] = React.useState<string>("");
  const [id, setId] = React.useState<string>("");
  const [loggedIn, setLoggedIn] = React.useState(false);

  React.useEffect(() => {
    Auth.configure(awscontext);
    const localUsername = localStorage.getItem("username");
    const localId = localStorage.getItem("userId");
    if (localUsername !== null && localId !== null) {
      setUser(localUsername);
      setId(localId);
      setLoggedIn(true);
    } else {
      Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then((userData) => {
          if (userData.attributes.email.split("@")[0] !== "" && userData.attributes.sub !== "") {
            setLoggedIn(true);
            setUser(userData.attributes.email.split("@")[0]);
            setId(userData.attributes.sub);
            localStorage.setItem("username", userData.attributes.email.split("@")[0]);
            localStorage.setItem("userId", userData.attributes.sub);
          }
        })
        .catch((err) => console.log(err));
    }
  });

  const amazonLogin = async () => {
    await Auth.federatedSignIn({ provider: "LoginWithAmazon" });
    setLoggedIn(true);
  };

  const googleLogin = async () => {
    await Auth.federatedSignIn({ provider: "Google" });
    setLoggedIn(true);
  };

  const login = async (email: string, password: string) => {
    try {
      await Auth.signIn(email, password);
      setLoggedIn(true);
    } catch (err) {
      setLoggedIn(false);
      if (err.code === "UserNotFoundException") {
        err.message = "Invalid username or password";
      }
      console.log(err);
      throw err;
    }
  };

  const signUp = async (email: string, password: string) => {
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
      },
    });
    await login(email, password);
  };

  const logout = () => {
    Auth.signOut().then((data) => {
      setUser("");
      setLoggedIn(false);
      localStorage.removeItem("username");
      localStorage.removeItem("userId");
      location.reload();
      return data;
    });
  };

  const confirmUser = (usernameOrEmail: string, code: string) => {
    Auth.confirmSignUp(usernameOrEmail, code).then(() => navigate("/signin"));
  };

  const uploadFile = async (s3Key: string, data: any, callback = () => {}) => {
    await Storage.put(s3Key, data);
    callback();
  };

  const getPresignedUrlWithKey = async (key: string): Promise<string> => {
    return await Storage.get(key, {
      download: false,
      level: "public",
    });
  };

  const getPresignedUrl = async (name: string, directory: string): Promise<string> => {
    const fileName = generateS3Key(id, name, directory, false);
    const url = await Storage.get(fileName, {
      download: false,
      level: "public",
    });
    return url as unknown as Promise<string>;
  };

  const getKeys = async (): Promise<string> => {
    const keys = await Storage.list(user, { level: "public" });
    const keyStrings = keys.map((key: any) => key.key);
    return keyStrings;
  };

  interface KeyValuePair {
    key: string;
    value: any;
  }

  function modifyObject(object: any, removeKeys: string[], keyValuePairs: KeyValuePair[]) {
    const newObject = object;
    removeKeys.forEach((key: string) => delete newObject[key]);
    keyValuePairs.forEach((pair: KeyValuePair) => (newObject[pair.key] = pair.value));
    return newObject;
  }
  const values = React.useMemo(
    () => ({
      user,
      id,
      login,
      logout,
      signUp,
      confirmUser,
      loggedIn,
      uploadFile,
      getPresignedUrl,
      getPresignedUrlWithKey,
      getKeys,
      amazonLogin,
      googleLogin,
    }),
    [user, id, loggedIn]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useAuthState = (): AuthState => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error("`useUser` hook must be used within a `UserProvider` component");
  }
  return context;
};
