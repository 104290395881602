// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-adventure-2-tsx": () => import("./../../../src/pages/adventure2.tsx" /* webpackChunkName: "component---src-pages-adventure-2-tsx" */),
  "component---src-pages-adventure-tsx": () => import("./../../../src/pages/adventure.tsx" /* webpackChunkName: "component---src-pages-adventure-tsx" */),
  "component---src-pages-create-monster-tsx": () => import("./../../../src/pages/create/monster.tsx" /* webpackChunkName: "component---src-pages-create-monster-tsx" */),
  "component---src-pages-create-npc-tsx": () => import("./../../../src/pages/create/npc.tsx" /* webpackChunkName: "component---src-pages-create-npc-tsx" */),
  "component---src-pages-edit-monster-tsx": () => import("./../../../src/pages/edit/monster.tsx" /* webpackChunkName: "component---src-pages-edit-monster-tsx" */),
  "component---src-pages-edit-npc-tsx": () => import("./../../../src/pages/edit/npc.tsx" /* webpackChunkName: "component---src-pages-edit-npc-tsx" */),
  "component---src-pages-explore-monster-tsx": () => import("./../../../src/pages/explore/monster.tsx" /* webpackChunkName: "component---src-pages-explore-monster-tsx" */),
  "component---src-pages-explore-npc-tsx": () => import("./../../../src/pages/explore/npc.tsx" /* webpackChunkName: "component---src-pages-explore-npc-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-master-tsx": () => import("./../../../src/pages/master.tsx" /* webpackChunkName: "component---src-pages-master-tsx" */),
  "component---src-pages-play-tsx": () => import("./../../../src/pages/play.tsx" /* webpackChunkName: "component---src-pages-play-tsx" */),
  "component---src-pages-retail-tsx": () => import("./../../../src/pages/retail.tsx" /* webpackChunkName: "component---src-pages-retail-tsx" */),
  "component---src-pages-s-3-tsx": () => import("./../../../src/pages/s3.tsx" /* webpackChunkName: "component---src-pages-s-3-tsx" */),
  "component---src-pages-saved-monster-tsx": () => import("./../../../src/pages/saved/monster.tsx" /* webpackChunkName: "component---src-pages-saved-monster-tsx" */),
  "component---src-pages-saved-npc-tsx": () => import("./../../../src/pages/saved/npc.tsx" /* webpackChunkName: "component---src-pages-saved-npc-tsx" */),
  "component---src-pages-sheet-tsx": () => import("./../../../src/pages/sheet.tsx" /* webpackChunkName: "component---src-pages-sheet-tsx" */),
  "component---src-pages-spellbook-tsx": () => import("./../../../src/pages/spellbook.tsx" /* webpackChunkName: "component---src-pages-spellbook-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-testmobile-tsx": () => import("./../../../src/pages/testmobile.tsx" /* webpackChunkName: "component---src-pages-testmobile-tsx" */)
}

