export const Table = {
  sizes: {
    sm: {
      table: {
        size: "small",
      },
    },
    md: {
      table: {
        size: "md",
      },
    },
    lg: {
      table: {
        size: "md",
      },
    },
    xl: {
      table: {
        size: "md",
      },
    },
  },
};
