export const Container = {
  baseStyle: {
    bg: "white",
  },
  sizes: {
    xs: {
      minW: "container.xs",
    },
    sm: {
      minW: "container.sm",
    },
    md: {
      minW: "container.md",
    },
    lg: {
      minW: "container.lg",
    },
    xl: {
      minW: "container.xl",
    },
  },
  variants: {},
};
