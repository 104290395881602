import React, { ReactElement, ReactNode, useState } from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";

export interface AlertContext {
  alert: ReactElement;
  addAlert: (success: boolean, text: string) => void;
}

const dummyAlertClient = {
  alert: <div />,
  addAlert: () => {
    throw Error("Client not implemented");
  },
};

export const AlertProvider = ({ children }: ChildrenProps) => {
  const [alert, setAlert] = useState(<div />);

  function addAlert(success: boolean, text: string) {
    const status = success ? "success" : "error";
    const newAlert = (
      <Alert status={status} position="fixed" zIndex={2}>
        <AlertIcon />
        {text}
      </Alert>
    );
    setAlert(newAlert);
    setTimeout(function () {
      setAlert(<div />);
    }, 2000);
  }

  return <AlertContext.Provider value={{ alert: alert, addAlert: addAlert }}>{children}</AlertContext.Provider>;
};

interface ChildrenProps {
  children: ReactNode;
}

const AlertContext = React.createContext<AlertContext>(dummyAlertClient);

export const useAlertContext = (): AlertContext => {
  const context = React.useContext(AlertContext);

  if (context === undefined) {
    throw new Error("useApiClient hook must be used within a ApiClient provider");
  }
  return context;
};
