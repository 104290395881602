import React, { ReactNode, useMemo, useContext, createContext } from "react";

export interface TrackingHistory {
  getHistory: () => any;
  addToHistory: (name: string, value?: any) => any;
  finalizeTrackingHistory: (eventName: string, category: string, value?: string) => any;
}

const dummyTrackingHistory: TrackingHistory = {
  getHistory: () => {
    throw Error("Pixel History Provider not set");
  },
  addToHistory: () => {
    throw Error("Pixel History Provider not set");
  },
  finalizeTrackingHistory: () => {
    throw Error("Pixel History Provider not set");
  },
};

const TrackingHistoryContext = createContext(dummyTrackingHistory);

interface ChildrenProps {
  children: ReactNode;
}

export const TrackingHistoryProvider = ({ children }: ChildrenProps) => {
  const history = {};

  const addToHistory = (name: string, value = true) => {
    history[name] = value;
  };

  const finalizeTrackingHistory = (eventName: string, category: string, value?: string) => {
    history["eventName"] = eventName;
    history["category"] = category;
    history["isMobile"] = screen.width < 1200;
    const currentDate = new Date().toISOString().split("T")[0];
    history["date"] = currentDate;

    if (value) {
      history["value"] = value;
    }

    return history;
  };

  const getHistory = () => {
    return history;
  };

  const values = useMemo(() => ({ addToHistory, finalizeTrackingHistory, getHistory }), []);

  return (
    <TrackingHistoryContext.Provider value={values}>{children}</TrackingHistoryContext.Provider>
  );
};

export const useTrackingHistory = (): TrackingHistory => {
  const context = useContext(TrackingHistoryContext);

  if (context === undefined) {
    throw new Error("useTrackingHistory must be used within TrackingHistory provider");
  }
  return context;
};
