// theme/index.js
import { extendTheme } from "@chakra-ui/react";

// Global style overrides
import { styles } from "./Styles";

import { colors } from "./Colors";

// Foundational style overrides
// import borders from './foundations/borders';

// Component style overrides
import { Button } from "./components/Button";
import { Input } from "./components/Input";
import { Container } from "./components/Container";
import { Table } from "./components/Table";

const overrides = {
  colors: colors,
  styles,
  // borders,
  // Other foundational style overrides go here
  components: {
    Input,
    Button,
    Table,
    Container,
  },
};

export default extendTheme(overrides);
