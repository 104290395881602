import React, { ReactElement, ReactNode } from "react";
import { Global } from "@emotion/react";
import belmont from "./fonts/Belmont Regular.woff2";
import neverwinter from "./fonts/Neverwinter.woff";
import newrocker from "./fonts/NewRocker-Regular.woff";
import baskerville from "./fonts/baskerville.woff";
import revorioum from "./fonts/IMMORTAL.woff";
import cinzel from "./fonts/Cinzel-Bold.woff";
import glober from "./fonts/Glober-SemiBold.otf";
import lato from "./fonts/Lato-Regular.ttf";
import latoBlack from "./fonts/Lato-Black.ttf";
import latoBold from "./fonts/Lato-Bold.ttf";
import linotteSemiBold from "./fonts/linotte-semibold.woff";
import linotteRegular from "./fonts/linotte-regular-webfont.woff";
import overpass from "./fonts/Overpass-VariableFont_wght.ttf";
import proza from "./fonts/ProzaLibre-Regular.ttf";
import prozaBold from "./fonts/ProzaLibre-Bold.ttf";
import halcyonRegular from "./fonts/halcyon/halcyon-regular.ttf";
import halcyonSemiBold from "./fonts/halcyon/halcyon-semibold.ttf";
import halcyonMedium from "./fonts/halcyon/halcyon-medium.ttf";
import halcyonBold from "./fonts/halcyon/halcyon-bold.ttf";
import halcyonBlack from "./fonts/halcyon/halcyon-black.woff";
import halcyonLight from "./fonts/halcyon/halcyon-light.ttf";
import sophiaLight from "./fonts/sofiapro-light.otf";
import alegreyaBold from "./fonts/alegreya/Alegreya-Bold.ttf";
import alegreya from "./fonts/alegreya/Alegreya-Regular.ttf";
import galdeano from "./fonts/Galdeano-Regular.ttf";

export const Fonts = () => (
  <Global
    styles={`
        @font-face {
            font-family: galdeano;
            src: url(${galdeano}) format('truetype');
            font-weight: 400;
            font-style: normal;
        }
        @font-face {
            font-family: alegreya;
            src: url(${alegreya}) format('truetype');
            font-weight: 400;
            font-style: normal;
        }
        @font-face {
            font-family: alegreya;
            src: url(${alegreyaBold}) format('truetype');
            font-weight: 500;
            font-style: normal;
        }
        @font-face {
            font-family: halcyon;
            src: url(${halcyonMedium}) format('truetype');
            font-weight: 300;
            font-style: normal;
        }
        @font-face {
            font-family: halcyon;
            src: url(${sophiaLight}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: halcyon;
            src: url(${halcyonSemiBold}) format('truetype');
            font-weight: 500;
            font-style: normal;
        }
        @font-face {
            font-family: halcyon;
            src: url(${halcyonBold}) format('truetype');
            font-weight: 600;
            font-style: normal;
        }
        @font-face {
            font-family: halcyon;
            src: url(${halcyonBlack}) format('truetype');
            font-weight: 800;
            font-style: normal;
        }
        @font-face {
            font-family: linotte;
            src: url(${linotteRegular}) format('truetype');
            font-weight: 400;
            font-style: normal;
        }
        @font-face {
            font-family: linotte;
            src: url(${linotteSemiBold}) format('truetype');
            font-weight: 500;
            font-style: normal;
        }
        @font-face {
            font-family: overpass;
            src: url(${overpass}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: proza;
            src: url(${proza}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: prozaBold;
            src: url(${prozaBold}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: cinzel;
            src: url(${cinzel}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: glober;
            src: url(${glober}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: lato;
            src: url(${lato}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: latoBlack;
            src: url(${latoBlack}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: latoBold   ;
            src: url(${latoBold}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: cinzel;
            src: url(${cinzel}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: belmont;
            src: url(${belmont}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: revorioum;
            src: url(${revorioum}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: newrocker;
            src: url(${newrocker}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: neverwinter;
            src: url(${neverwinter}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        @font-face {
            font-family: baskerville;
            src: url(${baskerville}) format("truetype-variations");
            font-weight: 1 999;
            font-streatch 0% 100%;
            font-optical-sizing: 0 100;
        }
    `}
  />
);
