import React, { ReactNode } from "react";
import faunadb from "faunadb";

export interface ApiClient {
  getClient: () => faunadb.Client;
  query: (expression: faunadb.ExprArg) => void;
  getTrackingId: () => number;
}

export const ApiClientProvider = ({ children }: ChildrenProps) => {
  const secret = process.env.GATSBY_FAUNA_KEY;
  const trackingId = Math.random();

  const client = new faunadb.Client({
    secret: secret || "",
    domain: "db.us.fauna.com",
    port: 443,
    scheme: "https",
  });

  function getClient(): faunadb.Client {
    return client;
  }

  function query(expression: faunadb.ExprArg): void {
    client.query(expression);
  }

  function getTrackingId(): number {
    return trackingId;
  }
  const values = React.useMemo(() => ({ getClient, getTrackingId, query }), []);

  return <ApiClientContext.Provider value={values}>{children}</ApiClientContext.Provider>;
};

const dummyApiClient: ApiClient = {
  getClient: () => {
    throw Error("Api Client Provider not set");
  },
  query: () => {
    throw Error("Api Client Provider not set");
  },
  getTrackingId: () => {
    throw Error("Api Client Provider not set");
  },
};

const ApiClientContext = React.createContext(dummyApiClient);

interface ChildrenProps {
  children: ReactNode;
}

export const useApiClient = (): ApiClient => {
  const context = React.useContext(ApiClientContext);

  if (context === undefined) {
    throw new Error("useApiClient hook must be used within a ApiClient provider");
  }
  return context;
};
