export const Button = {
  // style object for base or default style
  // styles for different sizes ("sm", "md", "lg")
  baseStyle: {
    color: "white",
    fontFamily: "linotte",
    fontSize: "1.2rem",
  },
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  // default values for `size` and `variant`
  defaultProps: {
    _hover: {},
    _focus: {},
    _active: {},
    _selected: {},
    // colorScheme: 'blue',
  },
};
